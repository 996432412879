$c-left-nav-width: 290px;
$c-left-nav-top: 95px;

body {
  -webkit-transition: 0.3s cubic-bezier(0.7, 0, 0.3, 1);
  transition: 0.3s cubic-bezier(0.7, 0, 0.3, 1);

  &.flyout-open {
    @include mq($from: desktop) {
      margin-left: $c-left-nav-width;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      html.cssanimations & {
        -webkit-transform: translateX($c-left-nav-width);
        transform: translateX($c-left-nav-width);
        margin: 0;
      }

      #mobile-offcanvas .c-sidebar-nav.flyout {
        display: block;
        position: absolute;
        left: -$c-left-nav-width;

        header.header {
          .button.tiny {
            height: auto !important;
          }
        }
      }
    }
  }
}

.top-bar {
  &.expanded {
    #tool-bar.loggedin {
      display: none;
    }
  }

  .desktop-only {
    display: none;

    @include mq($from: desktop) {
      display: inherit;
    }
  }
}

.c-sidebar-nav {
  @include mq($until: tablet-landscape) {
    &.flyout {
      display: none;
    }
  }

  @include mq($from: desktop) {
    width: $c-left-nav-width;

    &.flyout {
      left: -$c-left-nav-width;
      position: absolute;
      top: $c-left-nav-top;
      transition: 0.2s cubic-bezier(0.7, 0, 0.3, 1);
      -webkit-transition: 0.2s cubic-bezier(0.7, 0, 0.3, 1);
      width: $c-left-nav-width;
      z-index: 9999;
      height: 100vh;
      overflow-x: hidden;
      overflow-y: hidden;
      background: $color-warm-gray-8;

      &.open {
        left: 0;
      }

      .companyLogo .has-error {
        display: none;
      }
    }

    .c-sidebar-nav-content {
      position: relative;
      height: 80%;
      overflow-y: auto;
      scrollbar-width: none;
    }
  }

  header.header {
    background: $color-white;
    padding: $global-spacing-unit-small $global-spacing-unit;
    position: relative;

    @include mq($from: desktop) {
      padding: 20px;
    }

    .c-close-flyout,
    .hide-flyout {
      color: $color-warm-gray-11;
      display: none;
      font-size: $global-font-size-tiny + $global-font-size-tiny;
      line-height: $global-spacing-unit;
      position: absolute;
      right: 20px;
      top: 20px;

      @include mq($from: desktop) {
        display: block;
      }
    }

    hr {
      margin: $global-spacing-unit-tiny 0;

      @include mq($from: desktop) {
        margin: $global-spacing-unit-small 0;
      }
    }

    h2 {
      font-size: $global-font-size-large + $global-font-size-small;
      margin-bottom: 0;
    }

    .button.tiny {
      font-size: $global-font-size-tiny;
    }

    p {
      &.companyLogo {
        width: 90%;
      }

      padding-bottom: 0;
    }

    .c-left-navigation-logo {
      margin-bottom: $global-spacing-unit-small !important;
    }

    .c-left-navigation-logo.c-left-color {
      color: $color-warm-gray-8 !important;
    }

    .c-left-navigation-webgroup {
      width: 220px;
    }
  }

  .custom-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    > li {
      background: $color-warm-gray-8;
      padding-left: $global-spacing-unit-small;
      position: relative;
      -webkit-transition: 0.2s ease;
      transition: 0.2s ease;

      > a {
        color: $color-white;
        display: block;
        font-size: $global-font-size;
        overflow: hidden;
        padding: $global-spacing-unit-tiny $global-spacing-unit-small;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-style: none;
        font-weight: $global-font-weight-normal;

        @include mq($from: desktop) {
          font-size: $global-font-size-small + $global-spacing-unit-tiny;
          padding: $global-spacing-unit-small $global-spacing-unit;
        }

        .caption {
          font-size: $global-font-size-tiny;
          padding-left: $global-spacing-unit-small;
          vertical-align: 3px;
        }
      }

      > button {
        color: $color-white;
        font-size: $global-font-size;
        font-weight: $global-font-weight-normal;
        padding: $global-spacing-unit-tiny $global-spacing-unit-small;
        text-overflow: ellipsis;
        white-space: nowrap;

        @include mq($from: desktop) {
          font-size: $global-font-size-small + $global-spacing-unit-tiny;
          padding: $global-spacing-unit-small $global-spacing-unit-tiny;
          border-color: transparent;
        }

        .caption {
          font-size: $global-font-size-tiny;
          padding-left: $global-spacing-unit-small;
          vertical-align: 3px;
        }
      }

      .top-nav-button {
        width: 100%;
      }

      &:hover,
      &:focus {
        background: $color-warm-gray-11;
        cursor: pointer;
      }

      > ul {
        li {
          background: $color-white;
          border-bottom: 1px solid $color-warm-gray-2;

          a {
            border-style: none;
            color: $color-warm-gray-11;
            display: block;
            font-size: $global-font-size;
            font-weight: $global-font-weight-bold;
            line-height: 1.25;
            padding: $global-spacing-unit-tiny $global-spacing-unit-small;

            @include mq($from: desktop) {
              font-size: $global-font-size;
              padding: $global-spacing-unit-small;
            }

            .line1,
            .line2 {
              display: block;
            }

            .line1 {
              color: $color-warm-gray-11;
            }

            .line2 {
              color: $color-warm-gray-11;
              font-size: $global-font-size-small;
              font-weight: $global-font-weight-normal;
            }
          }

          button {
            @include mq($from: desktop) {
              font-size: $global-font-size;
              padding: $global-spacing-unit-tiny;
              width: 100%;
              text-align: left;
              border-color: transparent;
            }
          }

          &.has-child {
            ul a {
              font-size: $global-font-size-tiny;
              padding: $global-spacing-unit-tiny $global-spacing-unit-small;
            }
          }

          &:hover {
            background: $color-warm-gray-2;
          }
        }
      }

      li.has-child {
        > a {
          display: block;
          position: relative;
        }

        > a,
        .line1,
        .line2 {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        > a:before {
          content: '\f3d0';
          color: $color-warm-gray-5;
          font-size: $global-font-size + $global-spacing-unit-tiny;
          font-family: Ionicons;
          line-height: $global-spacing-unit;
          margin-top: -$global-spacing-unit-small;
          position: absolute;
          right: $global-spacing-unit;
          top: 50%;
          -webkit-transition: 0.2s ease;
          transition: 0.2s ease;
        }

        &.active {
          > a:before {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        }
      }

      &.active {
        background: $color-warm-gray-8;
      }

      &.segment {
        @include mq($from: desktop) {
          display: none;
        }
      }
    }

    .c-button-collapsor {
      position: absolute;
      top: 3px;
      margin-left: 210px;
    }
  }
}

.c-header-account-menu__btn-text {
  height: $global-spacing-unit;
  width: 100px;

  p {
    font-size: $global-font-size-tiny;
    font-weight: $global-font-weight-bold;
    line-height: $global-spacing-unit-small;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: $global-spacing-unit;
    margin-left: $global-spacing-unit-tiny;
  }
}
@media print {
  .c-sidebar-nav .header {
    display: none;
  }
}
