.what-we-do-menu {
  @include mq($from: desktop) {
    .c-header-mega-menu__section {
      & .c-header-nav__heading {
        border-top: 0;
        padding-top: $global-spacing-unit-small;
        .c-header-nav__link--heading {
          font-size: 1rem;
          line-height: 1.5;
        }
      }
      &.c-header-mega-menu__section--highlight .c-header-nav__heading {
        border-top: 1px solid;   
      }
    }
  }
}